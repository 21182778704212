<template>
	<div>
		<div class="box box_mm">
			<div class="box_main">
				<div style="height: 70vh; overflow-y: auto; padding-right: 0.5rem; box-sizing: border-box;">

					<div class="tc_title">基本信息</div>
					<div class="basebox">
						<el-form :model="baseinfo" :rules="rules" ref="baseinfo" label-width="110px"
							class="demo-ruleForm">
							<el-form-item label="调查表名称" prop="name" required>
								<el-input v-model="baseinfo.name"></el-input>
							</el-form-item>
							<el-form-item label="调查时间" required>
								<div style="display: flex;align-items: center;">
									<el-form-item prop="kstime">
										<el-date-picker v-model="baseinfo.kstime" type="datetime"
											value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择开始时间">
										</el-date-picker>
									</el-form-item>
									<div style="margin: 0 10px;">至</div>
									<el-form-item prop="jstime">
										<el-date-picker v-model="baseinfo.jstime" type="datetime"
											value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择结束时间">
										</el-date-picker>
									</el-form-item>
								</div>
							</el-form-item>
							<el-form-item label="选择调查对象" required="">
								<div style="display: flex;align-items: center;">
									<el-button type="primary" size="small" @click="select">选择</el-button>
								</div>
								<div>
									<span style="color: #999;">已选人员：</span>{{user_name}}
								</div>
							</el-form-item>
							<el-form-item label="添加问卷说明">
								<el-input v-model="baseinfo.sm" type="textarea" :rows="4" placeholder="请输入"></el-input>
							</el-form-item>
						</el-form>
					</div>

					<div class="tc_title">调查表内容</div>
					<div class="tablecont">
						<div v-for="(tmvalue, index) in baseinfo.tm" :key="tmvalue.weigh"
							style="display: flex;align-items: center;margin-bottom: 15px;">
							<el-cascader v-model="tmvalue.value" :options="cascaderOptions"
								@change="handleCascaderChange(tmvalue, index)"
								@active-item-change="handleActiveItemChange" clearable filterable placeholder="请选择"
								style="width: 100%;">
							</el-cascader>

							<div style="display: flex;align-items: center;margin-left: 15px;">
								<el-button @click="moveUp(index)" type="text" icon="el-icon-top" />
								<el-button @click="moveDown(index)" type="text" icon="el-icon-bottom" />
							</div>

							<el-button @click="removeOption(index)" type="danger" icon="el-icon-delete"
								style="margin-left: 20px;"></el-button>
						</div>
					</div>

					<div style="width: fit-content;display: flex;margin-top: 20px;">
						<el-button type="primary" size="mini" @click="addnewtiku">新增</el-button>
					</div>
				</div>

				<div style="display: flex; align-content: center; margin-top: 60px;">
					<el-button @click="backpage">取消</el-button>
					<el-button type="warning" @click="submitForm(2)">暂存</el-button>
					<el-button type="primary" @click="submitForm(1)">发布</el-button>
				</div>
			</div>
		</div>

		<selectuser ref="myselectuser" @selectedry="selectedry"></selectuser>
	</div>
</template>

<script>
	import selectuser from '@/components/select_user.vue'
	export default {
		components: {
			selectuser
		},
		data() {
			return {
				baseinfo: {
					name: '',
					kstime: '',
					jstime: '',
					ry: '',
					ry_name: '',
					sm: '',
					tm: [{
						value: [],
						weigh: 0
					}]
				},
				rules: {
					name: [{
						required: true,
						message: '请输入调查表名称',
						trigger: 'blur'
					}],
					kstime: [{
						required: true,
						message: '请选择开始时间',
						trigger: 'change'
					}],
					jstime: [{
						required: true,
						message: '请选择结束时间',
						trigger: 'change'
					}],
				},
				user_id: '',
				user_name: '',
				cascaderOptions: [],
				cascaderOptionsCache: {}, // 用于缓存分类数据
				tiku_options: [],
				questionCache: {} // 缓存问题数据
			}
		},
		created() {
			if (this.$route.query.id) { // 编辑的情况
				this.$post({
					url: '/api/questionnaire/details',
					params: {
						id: this.$route.query.id
					}
				}).then(async (res) => {
					// 回填数据
					this.baseinfo.id = res.id;
					this.baseinfo.name = res.name;
					this.baseinfo.kstime = res.kstime;
					this.baseinfo.jstime = res.jstime;
					this.baseinfo.sm = res.sm;
					this.user_id = res.ry;
					this.user_name = res.ry_name;

					// 将题目 ID 转换为对象数组并添加 weigh 属性
					const tmArray = res.tm.split(',').map(Number);
					this.baseinfo.tm = tmArray.map((item, index) => ({
						value: [],
						weigh: tmArray.length - index - 1 // 确保权重从大到小
					}));

					// 获取所有分类数据和问题数据
					await this.loadAllQuestionnaireTypesAndQuestions();

					// 根据题目 ID 进行回填
					for (let tmindex = 0; tmindex < this.baseinfo.tm.length; tmindex++) {
						const tmitem = this.baseinfo.tm[tmindex];
						const question = this.questionCache[tmArray[tmindex]];
						if (question) {
							tmitem.value = [question.questionnairetktype_id, tmArray[tmindex]];
							// 加载级联选项
							await this.loadQuestions(question.questionnairetktype_id, tmindex, tmArray[tmindex]);
						}
					}
				});
			} else {
				this.getQuestionnaireTypes(); // 获取分类数据
			}
		},
		methods: {
			addnewtiku() {
				const minWeigh = this.baseinfo.tm.length > 0 ? Math.min(...this.baseinfo.tm.map(item => item.weigh)) : 0;
				this.baseinfo.tm.push({
					value: [],
					weigh: minWeigh - 1
				});
			},
			removeOption(index) {
				if (this.baseinfo.tm.length > 1) {
					this.baseinfo.tm.splice(index, 1);
				} else {
					this.$message.error('至少需要一个');
				}
				this.updateWeigh();
			},
			moveUp(index) {
				if (index > 0) {
					const item = this.baseinfo.tm.splice(index, 1)[0];
					this.baseinfo.tm.splice(index - 1, 0, item);
					this.updateWeigh();
				}
			},
			moveDown(index) {
				if (index < this.baseinfo.tm.length - 1) {
					const item = this.baseinfo.tm.splice(index, 1)[0];
					this.baseinfo.tm.splice(index + 1, 0, item);
					this.updateWeigh();
				}
			},
			updateWeigh() {
				this.baseinfo.tm.forEach((item, index) => {
					item.weigh = this.baseinfo.tm.length - index - 1;
				});
			},
			handleActiveItemChange(value) {
				if (value.length === 1) {
					this.loadQuestions(value[0]);
				}
			},
			async loadAllQuestionnaireTypesAndQuestions() {
				await this.getQuestionnaireTypes(); // 获取分类数据
				await this.getAllQuestions(); // 获取所有问题数据
			},
			async getQuestionnaireTypes() {
				const res = await this.$post({
					url: '/api/questionnairetktype/index',
					params: {
						name: '',
						p: 1,
						size: 999
					}
				});
				this.cascaderOptions = res.list.map(item => ({
					value: item.id,
					label: item.name,
					children: [] // 初始时为空，选择后再加载
				}));
			},
			async getAllQuestions() {
				const res = await this.$post({
					url: '/api/questionnairetk/index',
					params: {
						name: '',
						p: 1,
						size: 999
					}
				});
				res.list.forEach(question => {
					this.questionCache[question.id] = question;
				});
			},
			async getQuestionById(questionId) {
				if (this.questionCache[questionId]) {
					return this.questionCache[questionId];
				}
				const res = await this.$post({
					url: '/api/questionnairetk/details',
					params: {
						id: questionId
					}
				});
				this.questionCache[questionId] = res;
				return res;
			},
			async handleCascaderChange(tmvalue, index) {
				const selectedTypeId = tmvalue.value[0];
				await this.loadQuestions(selectedTypeId, index);
			},
			async loadQuestions(typeId, index, selectedValue) {
				// 如果缓存中已有数据，直接使用缓存的数据
				if (this.cascaderOptionsCache[typeId]) {
					const children = this.cascaderOptionsCache[typeId];
					this.$set(this.cascaderOptions.find(item => item.value === typeId), 'children', children);
					if (selectedValue) {
						this.baseinfo.tm[index].value = [typeId, selectedValue];
					}
				} else {
					// 如果缓存中没有数据，则发起请求并缓存数据
					const res = await this.$post({
						url: '/api/questionnairetk/index',
						params: {
							questionnairetktype_id: typeId,
							name: '',
							p: 1,
							size: 999
						}
					});
					const children = res.list.map(item => ({
						value: item.id,
						label: item.name
					}));
					this.$set(this.cascaderOptions.find(item => item.value === typeId), 'children', children);
					// 缓存数据
					this.cascaderOptionsCache[typeId] = children;
					if (selectedValue) {
						this.baseinfo.tm[index].value = [typeId, selectedValue];
					}
				}
			},
			submitForm(type) {
				// 检查每个题目是否已选择
				for (let i = 0; i < this.baseinfo.tm.length; i++) {
					if (!this.baseinfo.tm[i].value[1]) {
						this.$message.warning(`题目 ${i + 1} 不能为空`);
						return;
					}
				}
				if (!this.user_id) {
					this.$message.warning('请选择调查对象');
					return;
				}

				this.$refs.baseinfo.validate(valid => {
					if (valid) {
						// 检查结束时间是否小于或等于开始时间
						if (new Date(this.baseinfo.jstime) <= new Date(this.baseinfo.kstime)) {
							this.$message.warning('结束时间不能小于或等于开始时间');
							return;
						}

						let dataobj = JSON.parse(JSON.stringify(this.baseinfo));
						dataobj.ry = this.user_id;
						dataobj.ry_name = this.user_name;

						dataobj.tm = dataobj.tm.map(item => item.value[1]).join(',');
						if (this.$route.query.id) {
							dataobj.weigh = this.baseinfo.tm.map(item => item.weigh).join(',');
						} else {
							dataobj.weigh = this.baseinfo.tm.map(item => item.weigh).reverse().join(',');
						}

						dataobj.type = type;
						console.log('表单验证成功', dataobj);
						// 提交表单逻辑
						this.$post({
							url: dataobj.id ? '/api/questionnaire/edit' : '/api/questionnaire/add',
							params: dataobj
						}).then((res) => {
							if (dataobj.id) {
								this.$message.success('修改成功');
							} else {
								this.$message.success('添加成功');
							}

							setTimeout(() => {
								this.backpage();
							}, 1000);
						});

					} else {
						console.log('表单验证失败');
						return false;
					}
				});
			},
			backpage() {
				this.$router.go(-1);
			},

			select() {
				let cxidlist = this.user_id ? this.user_id.split(',') : [];
				let cxnamelist = this.user_name ? this.user_name.split(',') : [];
				let list = [];
				if (cxidlist.length > 0) {
					cxidlist.forEach((item, index) => {
						let obj = {
							name: cxnamelist[index],
							id: Number(item)
						};
						list.push(obj);
					});
				}
				this.$refs.myselectuser.selectuser = list.length > 0 ? list : [];
				this.$refs.myselectuser.dialogVisible = true;
			},
			selectedry(e) {
				this.user_id = e.map((item) => {
					return item.id;
				}).join(',');
				this.user_name = e.map((item) => {
					return item.name;
				}).join(',');
			}
		}
	}
</script>
<style lang="scss" scoped>
	.box_main {
		background-color: #fff;
		padding: 15px 55px;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
	}

	.search_box {
		display: flex;
		align-items: center;
		font-size: 0.16rem;

		.search_item {
			display: flex;
			align-items: center;
			margin-right: 0.5rem;

		}
	}

	.box_cont {
		margin-top: 20px;
	}

	.btn_list {
		display: flex;
		align-items: center;
	}

	.tc_title {
		font-size: 0.18rem;
		font-weight: bold;
		color: #333;
		position: relative;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.tc_title:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 4px;
		left: 0px;
		background-color: rgb(26, 144, 253);
	}

	::v-deep td {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell>.cell {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		background-color: rgb(242, 242, 242);
		color: #777;
	}

	::v-deep .el-table th.el-table__cell.is-leaf,
	.el-table td.el-table__cell {
		border-bottom: none !important;
	}

	::v-deep .el-table td.el-table__cell {
		border-bottom: none !important;
	}

	::v-deep .el-table {
		margin-top: 10px;
		border-left: none !important;
	}

	::v-deep .el-table__fixed {
		border-bottom: none;
	}

	::v-deep .el-table::before {
		background-color: #fff !important;
	}

	::v-deep .el-table__fixed::before {
		background-color: #fff !important;
	}

	::v-deep .el-dialog__header {
		display: flex !important;
	}

	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}

	::v-deep .el-form-item__content {
		display: flex;
		flex-direction: column;
	}

	::v-deep .el-form-item__content {
		margin-left: 0 !important;
	}

	::v-deep .el-table__fixed-right::before {
		background-color: #fff !important;
	}

	.item_class {
		display: flex;
		align-items: center;
		width: 100%;
		box-sizing: border-box;

	}

	::v-deep .el-form-item__label {
		text-align: left;
	}

	::v-deep .tablecont .el-form-item {
		display: flex;
		flex-direction: column;
		margin-right: 150px;
	}

	::v-deep.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}

	.tc_title {
		font-size: 16px !important;
		font-weight: bold;
		color: #333;
		position: relative;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.tc_title:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 4px;
		left: 0px;
		background-color: #409EFF;
	}
</style>